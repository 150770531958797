import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import "../../Assets/css/contactus.css";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import { base_url } from "../../Store/constant";
import { Helmet } from "react-helmet";
const Contactus = () => {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("Billing Inquiry");
  const [msg, setMsg] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      subject,
      message: msg,
    };
    axios
      .post(base_url + "/postContactus", data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        if (response.status == 200) {
          swal(response.data.msg, "", "success");
          setEmail("");
          setName("");
          setSubject("Billing Inquiry");
          setMsg("");
        } else {
          swal(response.data.msg, "", "error");
        }
      })
      .catch((error) => {
        swal(error.response.data.msg, "", "error");
      });
  };

  return (
    <>
      <Helmet>
        <title>Contact Midjourney Free | AI-Powered Image Generation Tool</title>
        <meta name="description" content="Get in touch with Midjourney Free for inquiries, feedback, or support. Our team is
here to assist you with questions regarding our AI-powered image generation tool."></meta>
<meta name="keywords"
    content="midjourney free, midjourney, ai image generator, midjourney ai, ai photo generator, ai generated images, free ai image generator, ai image generator free, mid journey, generate ai images, midjourney ai free, midjourneyfree, Midjourney free contact"></meta>
         <link rel="alternate" to="https://www.journeyfree.ai/contact-us/about-us/" hreflang="en-us" />
        <link rel="canonical" to= "https://Journeyfree.ai/contact-us"/>
      </Helmet>
      <div className="contact-us pb-3">
        <Container className="pt-5 mt-3 mb-5">
          <Row className="justify-content-center">
            <Col sm={12}>
              <div className="text-center">
                <h1>Contact Us</h1>
                <p>
                If you need any help regarding <span className='mid-hide'>Mid</span>Journey AI image generator service please get in touch with us
                our team will surely help you.
                </p>

                {/* <p>
            Email:{" "}
            <Link to="mailto:support@journeyfree.ai" className="text-dark">
              <b>support@journeyfree.ai</b>
            </Link>
          </p> */}
              </div>
            </Col>
            <Col sm={8}>
              <div className="cont-right ">
                <Form onSubmit={handleFormSubmit} className="mt-4">
                <Row className="justify-content-center">
                        <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                              required
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              type="text"
                              placeholder="Full Name"
                            />
                          </Form.Group>
                        </Col>

                        <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              type="email"
                              placeholder="Email"
                            />
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Subject</Form.Label>
                            {/* <Form.Control
                      required
                      type="text"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      placeholder="Subject"
                    /> */}
                            <Form.Select
                              className="form-control"
                              required
                              value={subject}
                              onChange={(e) => setSubject(e.target.value)}
                            >
                              <option value="Billing Inquiry">
                                Billing Inquiry
                              </option>
                              <option value="Image Generation Assistance">
                                Image Generation Assistance
                              </option>
                              <option value="Subscription Issue">
                                Subscription Issue
                              </option>
                              <option value="Account Access">
                                Account Access
                              </option>
                              <option value="Feedback/Suggestions">
                                Feedback/Suggestions
                              </option>
                              <option value="Report a Bug/Error">
                                Report a Bug/Error
                              </option>
                              <option value="Cancel Subscription">
                                Cancel Subscription
                              </option>
                              {/* <option value="Request for Refund">
                                Request for Refund
                              </option> */}
                            </Form.Select>
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                              required
                              as="textarea"
                              value={msg}
                              onChange={(e) => setMsg(e.target.value)}
                              placeholder="Message"
                              rows={6}
                            />
                          </Form.Group>
                        </Col>

                        <Col lg={12} className="text-center mt-2">
                          <button type="submit" className="theme-btn">
                            Send
                          </button>
                        </Col>
                      </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Contactus;
